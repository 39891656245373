import { MsalProvider } from "@azure/msal-react";
import { observer } from "mobx-react-lite";

import {
  BrowserAuthError,
  PublicClientApplication,
  type EventMessage,
} from "@azure/msal-browser";
import { loginAction } from "../../actions/loginAction";
import { msalConfig } from "../../authConfig";
import { clearLocalStorgeExceptLocalCache } from "../../helpers/cacheHelper";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { updateTokenRefreshError } from "../../mutators/updateTokenRefreshError";
import type { WrapperProps } from "./WrapperProps";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);

const getEventDatabag = (event: EventMessage) => {
  return {
    loginId:
      event.payload && "correlationId" in event.payload
        ? event.payload.correlationId
        : "",
  };
};

export const msalEventCallback = (event: EventMessage) => {
  switch (event.eventType) {
    case "msal:loginFailure":
      updateTokenRefreshError(event.error?.message ?? "Unknown Error");
      break;

    case "msal:acquireTokenFailure":
      if (
        event.error instanceof BrowserAuthError &&
        event.error.errorCode === "monitor_window_timeout"
      ) {
        // MSAL will launch a iframe to handle the login
        // But actually SEVAL portal disabled iframe and then the token refresh will fail
        // So in this case we just redirect the user to login again
        // Clear all local storage except LOCAL_CACHE
        clearLocalStorgeExceptLocalCache();
        loginAction(msalInstance, "redirect");
      }

      updateTokenRefreshError(event.error?.message ?? "Unknown Error");
      break;
    case "msal:loginSuccess":
      telemetryHelper.logSignEvent("SignInSuccess", getEventDatabag(event));
      break;
    case "msal:loginStart":
      telemetryHelper.logSignEvent("SignInStart", getEventDatabag(event));
      break;

    default:
      break;
  }
};

msalInstance.addEventCallback(msalEventCallback);

export const AuthProvider = observer((props: WrapperProps) => {
  return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
});
